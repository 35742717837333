import React from "react";
import PageDescription from "../../../layout/page-description";
import AddMandate from "./modals/add-mandate";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import mandateService, { Mandate } from "../../../services/mandate.service";
import TableNeogen from "../../../layout/table-neogen";
import SwalNeogenFire from "../../../layout/swal-neogen";
import { usePaginatedResultItems } from "../../../hooks/usePaginatedResults";
import { PaginatedView } from "../../../layout/PaginatedView";
import Loader2 from "../../utilities/Loader2";
import mandateIndustriesService from "../../../services/mandate-industries.service";
import mandateIndustryGroupsService from "../../../services/mandate-industry-groups.service";
import AddMandateType from "./modals/add-mandate-type";
import { MandateType } from "../../../services/mandate-type.service";

export default function Mandates() {
    const itemsPerPage = 20;
    const [showAddMandate, setShowAddMandate] = React.useState(false);
    const [mandate, setMandate] = React.useState<Mandate | null>(null);
    const mandatesQuery = useQuery(["mandatesAll"], async () => {
        const result = await mandateService.getAll();
        return result?.data ?? [];
    });
    const mandateIndustryQuery = useQuery(["mandateIndustry"], async () => {
        const result = await mandateIndustriesService.getAll();
        return result?.data ?? [];
    });

    const mandateIndustryGroupQuery = useQuery(["mandateIndustryGroup"], async () => {
        const result = await mandateIndustryGroupsService.getAll();
        return result?.data ?? [];
    });

    type QueryKey = ["mandates"];
    const fetchMandates = async ({
        pageParam,
        queryKey,
    }: {
        pageParam?: { hasNextPage: boolean; startAt?: number };
        queryKey: QueryKey;
    }) => {
        const [_query] = queryKey;
        const { hasNextPage, startAt = 0 } = pageParam ?? { hasNextPage: true, startAt: 0 };
        try {
            if (!hasNextPage) {
                console.error("No more pages");
                return { pages: [], total: 0, hasNextPage: false };
            }

            const response = await mandateService.getMandates({
                offset: startAt,
                limit: 20,
            });

            if (!response) {
                return { pages: [], total: 0, hasNextPage: false };
            }
            const { total, pageItems } = response;
            return {
                pages: pageItems || [],
                hasNextPage,
                startAt: startAt + (pageItems.length || 0),
                total,
            };
        } catch (error) {
            console.error("Could not fetch mandates");
            throw error;
        }
    };
    const { data, isFetchingNextPage, fetchNextPage, isLoading, refetch } = useInfiniteQuery(
        ["mandates"],
        fetchMandates,
        {
            refetchOnMount: false,
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage) => {
                const hasNextPage = lastPage?.hasNextPage || null;
                return { hasNextPage, startAt: lastPage?.startAt };
            },
        },
    );

    const pageinatedMandates = usePaginatedResultItems<{ pages: Mandate[]; hasNextPage: boolean }, Mandate>(
        data,
        (response) => response.pages,
    );
    return (
        <>
            <PageDescription
                title="Mandates"
                description="Upload and manage mandates"
                buttons={[
                    {
                        label: "Add Mandate",
                        onClick: () => {
                            setShowAddMandate(true);
                        },
                        icon: "fas fa-file-circle-plus",
                    },
                    {
                        label: "Manage Mandate Types",
                        onClick: () => {
                            window.location.href = "/admin/mandate-types";
                        },
                        icon: "fas fa-copy",
                        type: "info",
                    },
                    {
                        label: "Manage Mandate Effects",
                        onClick: () => {
                            window.location.href = "/admin/mandate-effects";
                        },
                        icon: "fas fa-mask-face",
                        type: "warning",
                    },
                    {
                        label: "Manage Mandate Effect Groups",
                        onClick: () => {
                            window.location.href = "/admin/mandate-effect-groups";
                        },
                        icon: "fas fa-mask-face",
                        type: "danger",
                    },
                    {
                        label: "Manage Industries",
                        onClick: () => {
                            window.location.href = "/admin/industries";
                        },
                        icon: "fas fa-building-circle-arrow-right",
                        type: "secondary",
                    },
                ]}
            />
            {isLoading ? (
                <Loader2 />
            ) : (
                pageinatedMandates && (
                    <PaginatedView
                        onRequestToLoadMore={fetchNextPage}
                        isLoading={isFetchingNextPage}
                        currentCount={pageinatedMandates.length}
                        // pages[0] because all pages have the same `total`
                        totalCount={data?.pages[0]?.total || 0}
                    >
                        <TableNeogen
                            entries={pageinatedMandates ?? []}
                            ignoreFields={[
                                "q1",
                                "q2",
                                "q3",
                                "q4",
                                "q5",
                                "q6",
                                "q7",
                                "q8",
                                "fileId",
                                "orderId",
                                "name",
                                "broadstreetId",
                                "orderText",
                            ]}
                            customColumnClasses={[
                                { field: "city", classNames: "w-36" },
                                { field: "state", classNames: "w-36" },
                                { field: "county", classNames: "w-36" },
                            ]}
                            actions={[
                                {
                                    label: "Edit",
                                    onClick: (entry) => {
                                        setMandate(
                                            (mandatesQuery?.data as any)?.pageItems?.find((i: Mandate) => {
                                                return i.id == entry;
                                            }) ?? null,
                                        );
                                        setShowAddMandate(true);
                                    },
                                    icon: "fas fa-edit",
                                },
                                {
                                    label: "Delete",
                                    type: "danger",
                                    onClick: (entry) => {
                                        SwalNeogenFire({
                                            title: "Are you sure?",
                                            text: "You won't be able to revert this!",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonText: "Yes, delete it!",
                                            cancelButtonText: "No, keep it",
                                            confirmButtonColor: "#d33",
                                            reverseButtons: true,
                                        }).then(async (result) => {
                                            if (result.isConfirmed) {
                                                // Get a list of all the mandateIndustryGroups that are in this mandate
                                                // and delete them
                                                const entriesToDelete =
                                                    mandateIndustryGroupQuery.data?.filter(
                                                        (i) => i.mandate === entry,
                                                    ) ?? [];
                                                for (const entry of entriesToDelete) {
                                                    await mandateIndustryGroupsService.deleteByID(entry.id ?? -1);
                                                }
                                                // Get a list of all the mandateIndustries that are in this mandate
                                                // and delete them
                                                const entriesToDelete2 =
                                                    mandateIndustryQuery.data?.filter((i) => i.mandate === entry) ?? [];
                                                for (const entry of entriesToDelete2) {
                                                    await mandateIndustriesService.deleteByID(entry.id ?? -1);
                                                }

                                                await mandateService.deleteByID(entry);
                                                mandatesQuery.refetch();
                                                SwalNeogenFire({
                                                    title: "Deleted!",
                                                    text: "Your file has been deleted.",
                                                    icon: "success",
                                                    showConfirmButton: false,
                                                    timer: 1500,
                                                });
                                            }
                                        });
                                    },
                                    icon: "fas fa-trash",
                                },
                            ]}
                        />
                    </PaginatedView>
                )
            )}
            <AddMandate show={showAddMandate} close={() => setShowAddMandate(false)} mandate={mandate} />
        </>
    );
}
