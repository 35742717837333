import { useMemo } from "react";
import { Currency } from "../../billing/components";
import ButtonNeoGen from "../../layout/button-neogen";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { Company } from "../domain/company";
import { Payment } from "../../payments/domain/payment";
import { DateDisplay } from "../../layout/date-display";

export const QuartersTable = ({
    company,
    setShowEditQuarters,
    showEditQuarters,
    irsPayments,
}: {
    company: Company;
    setShowEditQuarters: (show: boolean) => void;
    showEditQuarters: boolean;
    irsPayments?: Payment[];
}) => {
    const totalQuarters = useMemo(() => {
        return (
            (company.q2_2020 || 0) +
            (company.q3_2020 || 0) +
            (company.q4_2020 || 0) +
            (company.q1_2021 || 0) +
            (company.q2_2021 || 0) +
            (company.q3_2021 || 0) +
            (company.q4_2021 || 0)
        );
    }, [company]);

    const q2_2020_payment = irsPayments?.find((p) => p.quarter === "Q2 2020");
    const q3_2020_payment = irsPayments?.find((p) => p.quarter === "Q3 2020");
    const q4_2020_payment = irsPayments?.find((p) => p.quarter === "Q4 2020");
    const q1_2021_payment = irsPayments?.find((p) => p.quarter === "Q1 2021");
    const q2_2021_payment = irsPayments?.find((p) => p.quarter === "Q2 2021");
    const q3_2021_payment = irsPayments?.find((p) => p.quarter === "Q3 2021");
    const q4_2021_payment = irsPayments?.find((p) => p.quarter === "Q4 2021");

    const totalPayouts = useMemo(() => {
        return (
            (q2_2020_payment?.amount || 0) +
            (q3_2020_payment?.amount || 0) +
            (q4_2020_payment?.amount || 0) +
            (q1_2021_payment?.amount || 0) +
            (q2_2021_payment?.amount || 0) +
            (q3_2021_payment?.amount || 0) +
            (q4_2021_payment?.amount || 0)
        );
    }, [irsPayments]);

    return (
        <>
            <div style={{ marginBottom: 12, display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 14, fontWeight: "bold" }}>Quarters Submitted to IRS</p>
                <ButtonNeoGen onClick={() => setShowEditQuarters(!showEditQuarters)}>Edit</ButtonNeoGen>
            </div>
            <Table>
                <THead>
                    <Tr>
                        <Th>Quarter</Th>
                        <Th>Filed Amount</Th>
                        <Th>Payout Amount</Th>
                        <Th>Payout Date</Th>
                    </Tr>
                </THead>
                <TBody>
                    <Tr>
                        <Td>Q2 2020</Td>
                        <Td>
                            <Currency amount={company.q2_2020 || 0} />
                        </Td>
                        <Td>{q2_2020_payment ? <Currency amount={q2_2020_payment.amount} /> : "-"}</Td>
                        <Td>{q2_2020_payment ? <DateDisplay date={q2_2020_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr>
                        <Td>Q3 2020</Td>
                        <Td>
                            <Currency amount={company.q3_2020 || 0} />
                        </Td>
                        <Td>{q3_2020_payment ? <Currency amount={q3_2020_payment.amount} /> : "-"}</Td>
                        <Td>{q3_2020_payment ? <DateDisplay date={q3_2020_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr>
                        <Td>Q4 2020</Td>
                        <Td>
                            <Currency amount={company.q4_2020 || 0} />
                        </Td>
                        <Td>{q4_2020_payment ? <Currency amount={q4_2020_payment.amount} /> : "-"}</Td>
                        <Td>{q4_2020_payment ? <DateDisplay date={q4_2020_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr>
                        <Td>Q1 2021</Td>
                        <Td>
                            <Currency amount={company.q1_2021 || 0} />
                        </Td>
                        <Td>{q1_2021_payment ? <Currency amount={q1_2021_payment.amount} /> : "-"}</Td>
                        <Td>{q1_2021_payment ? <DateDisplay date={q1_2021_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr>
                        <Td>Q2 2021</Td>
                        <Td>
                            <Currency amount={company.q2_2021 || 0} />
                        </Td>
                        <Td>{q2_2021_payment ? <Currency amount={q2_2021_payment.amount} /> : "-"}</Td>
                        <Td>{q2_2021_payment ? <DateDisplay date={q2_2021_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr>
                        <Td>Q3 2021</Td>
                        <Td>
                            <Currency amount={company.q3_2021 || 0} />
                        </Td>
                        <Td>{q3_2021_payment ? <Currency amount={q3_2021_payment.amount} /> : "-"}</Td>
                        <Td>{q3_2021_payment ? <DateDisplay date={q3_2021_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr>
                        <Td>Q4 2021</Td>
                        <Td>
                            <Currency amount={company.q4_2021 || 0} />
                        </Td>
                        <Td>{q4_2021_payment ? <Currency amount={q4_2021_payment.amount} /> : "-"}</Td>
                        <Td>{q4_2021_payment ? <DateDisplay date={q4_2021_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr className="bg-gray-50">
                        <Td>
                            <b>Total</b>
                        </Td>
                        <Td>
                            <b>
                                <Currency amount={totalQuarters} />
                            </b>
                        </Td>
                        <Td>
                            <b>
                                <Currency amount={totalPayouts} />
                            </b>
                        </Td>
                        <Td></Td>
                    </Tr>
                </TBody>
            </Table>
        </>
    );
};
