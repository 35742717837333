import React, { useEffect } from "react";
import ModalDialog from "../../../../layout/modal-dialog";
import mandateEffectGroupService, { MandateEffectGroup } from "../../../../services/mandate-effect-group.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import mandateEffectGroupEntriesService from "../../../../services/mandate-effect-group-entries.service";
import mandateEffectService from "../../../../services/mandate-effect.service";
import PrintPre from "../../../../layout/print-pre";
import CheckBoxNeoGenControlled from "../../../../layout/checkbox-controlled";
import InputControlled from "../../../../layout/input-controlled";

function AddMandateEffectGroup({
    show,
    close,
    mandateEffectGroup,
}: {
    show: boolean;
    close: () => void;
    mandateEffectGroup: MandateEffectGroup | null;
}) {
    const queryCache = useQueryClient();
    const [enabledEffects, setEnabledEffects] = React.useState<number[]>([]);
    const [removedEffects, setRemovedEffects] = React.useState<number[]>([]);
    const [groupTitle, setGroupTitle] = React.useState<string>("");
    const mandateEffectGroupEntriesQuery = useQuery(["mandateEffectGroupEntries"], async () => {
        const response = await mandateEffectGroupEntriesService.getAll();
        if (response) {
            return response.data;
        }
    });
    const mandateEffectsQuery = useQuery(["mandateEffects"], async () => {
        const response = await mandateEffectService.getAll();
        if (response) {
            return response.data;
        }
    });
    useEffect(() => {
        // If in edit mode prepopulate the enabled effects
        if (mandateEffectGroup) {
            mandateEffectGroupEntriesQuery.data
                ?.filter((e) => !removedEffects.includes(e.id))
                ?.forEach((entry) => {
                    // alert("Comparing " + entry.mandateEffectGroup + " with " + mandateEffectGroup.id);
                    if (
                        entry.mandateEffectGroup === mandateEffectGroup.id &&
                        !enabledEffects.includes(entry.mandateEffect)
                    ) {
                        setEnabledEffects([...enabledEffects, entry.mandateEffect]);
                    }
                });
            setGroupTitle(mandateEffectGroup.name ?? "");
        }
    }, [enabledEffects, mandateEffectGroup, mandateEffectGroupEntriesQuery.data, removedEffects]);
    useEffect(() => {
        setRemovedEffects([]);
    }, [show]);
    async function save() {
        console.log("Saving");
        if (mandateEffectGroup) {
            // Save existing
            // First delete any existing entries
            if (mandateEffectGroupEntriesQuery.data) {
                for (const entry of mandateEffectGroupEntriesQuery.data) {
                    if (entry.mandateEffectGroup === mandateEffectGroup.id) {
                        await mandateEffectGroupEntriesService.deleteByID(entry.id);
                    }
                }
            }
            // Then add the new ones
            for (const effect of enabledEffects.filter((e) => !removedEffects.includes(e)) ?? []) {
                await mandateEffectGroupEntriesService.create({
                    mandateEffectGroup: mandateEffectGroup.id,
                    mandateEffect: effect,
                });
            }
            // Update the title
            await mandateEffectGroupService.update(mandateEffectGroup.id, { name: groupTitle });
            queryCache.invalidateQueries(["mandateEffectGroupEntries"]);
            queryCache.invalidateQueries(["mandateEffectGroups"]);
            close();
        } else {
            // Save new
            // Create the new group
            const newMandateEffectGroup = await mandateEffectGroupService.create({
                name: groupTitle,
            });
            if (newMandateEffectGroup) {
                // Now add the entries
                for (const effect of enabledEffects) {
                    await mandateEffectGroupEntriesService.create({
                        mandateEffectGroup: newMandateEffectGroup.data?.id ?? -1,
                        mandateEffect: effect,
                    });
                }
                queryCache.invalidateQueries(["mandateEffectGroupEntries"]);
                close();
            }
        }
    }
    if (mandateEffectGroupEntriesQuery.isLoading || mandateEffectsQuery.isLoading) return <div>Loading...</div>;
    return (
        <>
            <ModalDialog
                close={() => close()}
                show={show}
                title={mandateEffectGroup ? "Edit Mandate Effect Group" : "Add Mandate Effect Group"}
                okAction={() => {
                    save();
                }}
            >
                <InputControlled value={groupTitle} onChange={(v) => setGroupTitle(v)} />
                {mandateEffectsQuery.data?.map((mandateEffect) => (
                    <div key={mandateEffect.id}>
                        <CheckBoxNeoGenControlled
                            value={
                                enabledEffects.includes(mandateEffect.id ?? -1) &&
                                !removedEffects.includes(mandateEffect.id ?? -1)
                                    ? true
                                    : false
                            }
                            name={mandateEffect.effectName ?? ""}
                            label={(mandateEffect.effectName ?? "")
                                .toLowerCase()
                                .replace(/\b[a-z]/g, function (letter) {
                                    return letter.toUpperCase();
                                })}
                            setValue={(value) => {
                                if (value.target.checked) {
                                    setEnabledEffects([...enabledEffects, mandateEffect.id ?? -1]);
                                    setRemovedEffects((c) => c.filter((id) => id !== mandateEffect.id));
                                } else {
                                    alert("Unchecking " + mandateEffect.id);
                                    setRemovedEffects([...removedEffects, mandateEffect.id ?? -1]);
                                    setEnabledEffects((c) => c.filter((id) => id !== mandateEffect.id));
                                }
                            }}
                        />
                    </div>
                ))}
            </ModalDialog>
        </>
    );
}

export default AddMandateEffectGroup;
