import { z } from "zod";
import { Company } from "../domain/company";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useForm } from "../../hooks/useForm";
import { useUpdateCompany } from "../hooks/use-update-company";
import { useMutation } from "@tanstack/react-query";
import { Form } from "../../layout/form/form";
import { CurrencyField } from "../../layout/form/currency-field";
import ButtonNeoGen from "../../layout/button-neogen";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { Payment } from "../../payments/domain/payment";
import { Currency } from "../../billing/components";
import { DateDisplay } from "../../layout/date-display";

const schema = z.object({
    q2_2020: z.number().min(0),
    q3_2020: z.number().min(0),
    q4_2020: z.number().min(0),
    q1_2021: z.number().min(0),
    q2_2021: z.number().min(0),
    q3_2021: z.number().min(0),
    q4_2021: z.number().min(0),
});

type Data = z.infer<typeof schema>;

export const EditQuartersTable = ({
    company,
    onCompanyUpdated: onCompanyUpdated,
    irsPayments,
}: {
    company: Company;
    onCompanyUpdated: (company: Company) => void;
    irsPayments?: Payment[];
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({
        schema,
        defaultValues: {
            q2_2020: company.q2_2020 || 0,
            q3_2020: company.q3_2020 || 0,
            q4_2020: company.q4_2020 || 0,
            q1_2021: company.q1_2021 || 0,
            q2_2021: company.q2_2021 || 0,
            q3_2021: company.q3_2021 || 0,
            q4_2021: company.q4_2021 || 0,
        },
    });

    const q2_2020_payment = irsPayments?.find((p) => p.quarter === "Q2 2020");
    const q3_2020_payment = irsPayments?.find((p) => p.quarter === "Q3 2020");
    const q4_2020_payment = irsPayments?.find((p) => p.quarter === "Q4 2020");
    const q1_2021_payment = irsPayments?.find((p) => p.quarter === "Q1 2021");
    const q2_2021_payment = irsPayments?.find((p) => p.quarter === "Q2 2021");
    const q3_2021_payment = irsPayments?.find((p) => p.quarter === "Q3 2021");
    const q4_2021_payment = irsPayments?.find((p) => p.quarter === "Q4 2021");

    const updateCompanyMutation = useUpdateCompany();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCompany = await updateCompanyMutation.mutateAsync({
                authToken,
                id: company.id,
                data: {
                    q2_2020: data.q2_2020 || 0,
                    q3_2020: data.q3_2020 || 0,
                    q4_2020: data.q4_2020 || 0,
                    q1_2021: data.q1_2021 || 0,
                    q2_2021: data.q2_2021 || 0,
                    q3_2021: data.q3_2021 || 0,
                    q4_2021: data.q4_2021 || 0,
                },
            });
            return updatedCompany;
        },
    });

    const handleSubmit = async (data: Data) => {
        const company = await submitMutation.mutateAsync(data);
        onCompanyUpdated(company);
    };

    return (
        <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={updateCompanyMutation.error as any}>
            <div style={{ marginBottom: 12, display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 14, fontWeight: "bold" }}>Quarters Submitted to IRS</p>
                <ButtonNeoGen type="submit">Save</ButtonNeoGen>
            </div>
            <Table>
                <THead>
                    <Tr>
                        <Th>Quarter</Th>
                        <Th>Filed Amount</Th>
                        <Th>Payout Amount</Th>
                        <Th>Payout Date</Th>
                    </Tr>
                </THead>
                <TBody>
                    <Tr>
                        <Td>Q2 2020</Td>
                        <Td>
                            <CurrencyField {...form.getFieldProps("q2_2020")} />
                        </Td>
                        <Td>{q2_2020_payment ? <Currency amount={q2_2020_payment.amount} /> : "-"}</Td>
                        <Td>{q2_2020_payment ? <DateDisplay date={q2_2020_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr>
                        <Td>Q3 2020</Td>
                        <Td>
                            <CurrencyField {...form.getFieldProps("q3_2020")} />
                        </Td>
                        <Td>{q3_2020_payment ? <Currency amount={q3_2020_payment.amount} /> : "-"}</Td>
                        <Td>{q3_2020_payment ? <DateDisplay date={q3_2020_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr>
                        <Td>Q4 2020</Td>
                        <Td>
                            <CurrencyField {...form.getFieldProps("q4_2020")} />
                        </Td>
                        <Td>{q4_2020_payment ? <Currency amount={q4_2020_payment.amount} /> : "-"}</Td>
                        <Td>{q4_2020_payment ? <DateDisplay date={q4_2020_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr>
                        <Td>Q1 2021</Td>
                        <Td>
                            <CurrencyField {...form.getFieldProps("q1_2021")} />
                        </Td>
                        <Td>{q1_2021_payment ? <Currency amount={q1_2021_payment.amount} /> : "-"}</Td>
                        <Td>{q1_2021_payment ? <DateDisplay date={q1_2021_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr>
                        <Td>Q2 2021</Td>
                        <Td>
                            <CurrencyField {...form.getFieldProps("q2_2021")} />
                        </Td>
                        <Td>{q2_2021_payment ? <Currency amount={q2_2021_payment.amount} /> : "-"}</Td>
                        <Td>{q2_2021_payment ? <DateDisplay date={q2_2021_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr>
                        <Td>Q3 2021</Td>
                        <Td>
                            <CurrencyField {...form.getFieldProps("q3_2021")} />
                        </Td>
                        <Td>{q3_2021_payment ? <Currency amount={q3_2021_payment.amount} /> : "-"}</Td>
                        <Td>{q3_2021_payment ? <DateDisplay date={q3_2021_payment.date} /> : "-"}</Td>
                    </Tr>
                    <Tr>
                        <Td>Q4 2021</Td>
                        <Td>
                            <CurrencyField {...form.getFieldProps("q4_2021")} />
                        </Td>
                        <Td>{q4_2021_payment ? <Currency amount={q4_2021_payment.amount} /> : "-"}</Td>
                        <Td>{q4_2021_payment ? <DateDisplay date={q4_2021_payment.date} /> : "-"}</Td>
                    </Tr>
                </TBody>
            </Table>
        </Form>
    );
};
