import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../layout/modal-dialog";
import { triggerAi } from "../actions/trigger-ai";
import { useAuth } from "../../auth/use-auth";
import Loader2 from "../../sections/utilities/Loader2";

export const TriggerAIModal = ({
    onClose,
    mandateId,
    onProcessed,
}: {
    onClose: () => void;
    mandateId: number;
    onProcessed: () => void;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const triggerAiMutation = useMutation({
        mutationFn: () => triggerAi({ authToken, id: mandateId }),
    });

    const handleTriggerAi = async () => {
        await triggerAiMutation.mutateAsync();
        onProcessed();
    };

    return (
        <ModalDialog
            title="Process Mandate"
            show
            close={onClose}
            okAction={handleTriggerAi}
            okText="Process Mandate"
            isLoading={triggerAiMutation.isLoading}
        >
            <div style={{ paddingBottom: 20 }}>
                <p>Process this mandate with AI?</p>
                <p>This could change the mandate effects, summary and other important information.</p>
            </div>
        </ModalDialog>
    );
};
