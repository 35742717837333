import { useCallback, useEffect, useRef, useState } from "react";
import Loader2 from "../../sections/utilities/Loader2";
import PageDescription from "../../layout/page-description";
import { debounce } from "lodash";
import { useNavigate } from "react-router";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import ButtonNeoGen from "../../layout/button-neogen";
import { useBreakpoint } from "../../hooks/appMedia";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import OptionsDropDown from "../../layout/options-dropdown";
import SearchField from "../../layout/search-field";
import { ViewportList } from "react-viewport-list";
import { useInfiniteMandates } from "../hooks/use-mandates";
import { useAuth } from "../../auth/use-auth";
import { SelectField } from "../../layout/form/select-field";
import { states as listOfStates } from "../../states";
import { Select } from "../../layout/form/select-input";
import qs from "qs";

function MandatesPage({ darkMode }: { darkMode?: boolean }) {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null);

    const params = new URLSearchParams(window.location.search);
    const searchParam = params.get("search");
    const statesParam = params.get("states");
    console.log(statesParam);
    const [states, setStates] = useState<string[] | undefined>(statesParam ? statesParam.split(",") : undefined);
    const [search, setSearch] = useState(searchParam || "");
    const [filters, setFilters] = useState<{ search?: string; states?: string[] } | undefined>(() => {
        return {
            search: searchParam || undefined,
            states: statesParam ? statesParam.split(",") : undefined,
        };
    });

    const mandatesQuery = useInfiniteMandates({
        authToken,
        filters: {
            where: {
                ...(filters?.search && filters.search?.length > 0 ? { name: { like: `%${filters.search}%` } } : {}),
                ...(filters?.states && filters.states.length > 0 ? { state: { inq: filters.states } } : {}),
            },
        },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (params: { search?: string; states?: string[] }) => {
                setFilters({ ...filters, ...params });
                const queryParams = qs.stringify(params, { skipNulls: true, arrayFormat: "comma" });
                navigate(`/mandates?${queryParams}`);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );

    useEffect(() => {
        updateDebouncedSearch({ search, states });
    }, [search, states, updateDebouncedSearch]);

    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    const loadMoreItems = useCallback(
        debounce(
            () => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                mandatesQuery.fetchNextPage();
            },
            1000,
            { leading: true },
        ),
        [],
    );

    const mandates = mandatesQuery.mandates || [];

    return (
        <>
            <PageDescription title="Mandates">
                <div className="flex flex-1 flex-row gap-3 justify-start">
                    <div style={{ textAlign: "left", width: 300 }}>
                        <Select
                            options={listOfStates.map((s) => ({ value: s.name, label: s.name }))}
                            isMultiple
                            onChange={(s) => setStates(s as any)}
                            value={states as any}
                            placeholder="Select states"
                        />
                    </div>
                    <SearchField search={search} setSearch={setSearch} placeholder="Search mandates..." />
                </div>
            </PageDescription>
            <div ref={ref} style={{ height: "calc(100vh - 210px)", overflow: "auto" }}>
                {false ? (
                    <div className={"p-5 text-center"}>
                        <Loader2 />
                    </div>
                ) : !isMobile && !isTablet ? (
                    <>
                        <Table>
                            <THead sticky>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>State</Th>
                                    <Th>County</Th>
                                    <Th>City</Th>
                                    <Th />
                                </Tr>
                            </THead>
                            <TBody>
                                {mandatesQuery.isLoading && (
                                    <Tr>
                                        <Td colSpan={5} style={{ textAlign: "center" }}>
                                            No mandates
                                        </Td>
                                    </Tr>
                                )}
                                {(mandatesQuery.mandates || []).length > 0 && (
                                    <ViewportList
                                        viewportRef={ref}
                                        items={mandatesQuery.mandates}
                                        renderSpacer={({ ref, style }) => <Tr ref={ref} style={style} />}
                                        scrollThreshold={100}
                                        initialPrerender={40}
                                        overscan={10}
                                    >
                                        {(mandate, index) => {
                                            if (mandates.length > 10 && mandates.length - index < 5) {
                                                loadMoreItems();
                                            }
                                            return (
                                                <Tr key={mandate.id}>
                                                    <Td>{mandate.name || "-"}</Td>
                                                    <Td>{mandate.state || "-"}</Td>
                                                    <Td>{mandate.counties?.join(", ") || "-"}</Td>
                                                    <Td>{mandate.cities?.join(", ") || "-"}</Td>
                                                    <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                        <ButtonNeoGen
                                                            size="sm"
                                                            onClick={() => {
                                                                navigate(`/mandates/${mandate.id}`);
                                                            }}
                                                        >
                                                            Open mandate
                                                        </ButtonNeoGen>
                                                    </Td>
                                                </Tr>
                                            );
                                        }}
                                    </ViewportList>
                                )}
                            </TBody>
                        </Table>
                    </>
                ) : (
                    <ul>
                        {(mandatesQuery.mandates || []).map((mandate) => (
                            <li
                                key={mandate.id}
                                className="bg-gray-50 dark:bg-gray-700 dark:border-gray-900 p-4 rounded-lg shadow-md mb-4"
                            >
                                <div className="flex items-start justify-between">
                                    <div>
                                        <div className="flex gap-2 pb-1">
                                            <BuildingOffice2Icon
                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                className="w-5 h-5"
                                            />
                                            <span>{mandate.name || "Unknown"}</span>
                                        </div>
                                        <div className="flex gap-2 pb-1">
                                            <BuildingOffice2Icon
                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                className="w-5 h-5"
                                            />
                                            <span>{mandate.cities || "Unknown"}</span>
                                        </div>
                                        <div className="flex gap-2 pb-1">
                                            <BuildingOffice2Icon
                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                className="w-5 h-5"
                                            />
                                            <span>{mandate.state || "Unknown"}</span>
                                        </div>
                                    </div>
                                    <OptionsDropDown
                                        asEllipses
                                        options={[
                                            {
                                                label: "Open mandate",
                                                action: () => navigate(`/mandates/${mandate.id}`),
                                                icon: "fa fa-pencil",
                                            },
                                        ]}
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    );
}

export default MandatesPage;
