import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company, companySchema } from "../../domain/company";
import { NumberField } from "../../../layout/form/number-field";
import { TextAreaField } from "../../../layout/form/text-area-field";
import { User, userSchema } from "../../../jason-proof-of-concept/users/domain/user";
import { Field } from "../../../layout/form/field";
import { useCreatePaymentMutation } from "../../../payments/hooks/use-create-payment";
import { DateTimeField } from "../../../layout/form/date-field";
import { useAuth } from "../../../auth/use-auth";
import { Payment } from "../../../payments/domain/payment";
import { TextField } from "../../../layout/form/text-field";
import { SelectField } from "../../../layout/form/select-field";
import { CurrencyField } from "../../../layout/form/currency-field";

const schema = z.object({
    amount: z.number(),
    date: z.date(),
    notes: z.string().nullish(),
    from: z.string().nullish(),
    to: z.string().nullish(),
    forCompanyId: z.number().nullish(),
    chequeNumber: z.string().nullish(),
    quarter: z.string().nullish(),
    taxes: z.number().nullish(),
    interest: z.number().nullish(),
});

type Data = z.infer<typeof schema>;

export const CreateIRSPaymentModal = ({
    onClose,
    companyId,
    defaultValues,
    onPaymentCaptured,
}: {
    onClose: () => any;
    companyId: number;
    defaultValues?: Partial<Payment>;
    onPaymentCaptured: (payment: Payment) => void;
}) => {
    const auth = useAuth();
    const authedUser = auth.expectUser();
    const authToken = getAuthTokenNoThrow() || "no-auth-token";

    const form = useForm({
        schema,
        defaultValues: {
            ...defaultValues,
            from: "IRS",
            to: "Lockbox",
            date: new Date(),
        },
    });

    const formData = form.getValues();

    const createPaymentMutation = useCreatePaymentMutation();

    const handleSubmit = async (data: Data) => {
        const payment = await createPaymentMutation.mutateAsync({
            authToken,
            data: {
                createdById: authedUser.id,
                companyId,
                interest: data.interest || 0,
                taxes: data.taxes || 0,
                forCompanyId: companyId,
                ...data,
                notes: data.notes || "",
            },
        });
        onPaymentCaptured(payment);
    };

    return (
        <ModalDialog show title={"Record IRS payout"} close={onClose} showOk={false} showCancel={false} size="sm">
            <p className="text-center mb-6 text-gray-400">Create a payment from the IRS which has been received.</p>
            <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={createPaymentMutation.error as any}>
                <div className="flex flex-row">
                    <div className="flex-1">
                        <Field label="From">{formData.from}</Field>
                    </div>
                    <div className="flex-1">
                        <Field label="To">{formData.to}</Field>
                    </div>
                </div>
                <SelectField
                    isSearchable={false}
                    label="Quarter"
                    {...form.getFieldProps("quarter")}
                    options={[
                        { value: "Q2 2020", label: "Q2 2020" },
                        { value: "Q3 2020", label: "Q3 2020" },
                        { value: "Q4 2020", label: "Q4 2020" },
                        { value: "Q1 2021", label: "Q1 2021" },
                        { value: "Q2 2021", label: "Q2 2021" },
                        { value: "Q3 2021", label: "Q3 2021" },
                        { value: "Q4 2021", label: "Q4 2021" },
                    ]}
                />
                <TextField label="Cheque Number" {...form.getFieldProps("chequeNumber")} />
                <DateTimeField label="Date" dateFormat="yyyy/MM/dd hh:mm" {...form.getFieldProps("date")} />
                <CurrencyField label="Amount" {...form.getFieldProps("amount")} />
                <CurrencyField label="Taxes" {...form.getFieldProps("taxes")} />
                <CurrencyField label="Interest" {...form.getFieldProps("interest")} />
                <TextAreaField label="Notes" {...form.getFieldProps("notes")} />
                <div className="flex justify-end gap-4">
                    <ButtonNeoGen type="outline" disabled={createPaymentMutation.isLoading} onClick={() => onClose()}>
                        Cancel
                    </ButtonNeoGen>
                    <ButtonNeoGen type="submit" disabled={createPaymentMutation.isLoading}>
                        <i className="fa fa-check text-green-500 mr-2" />
                        Record IRS payment
                    </ButtonNeoGen>
                </div>
            </Form>
        </ModalDialog>
    );
};
